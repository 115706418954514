import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const ClassPositionBest = ( {curDriver, fastestClassRacer, classPosition, classRacers, isOverall} ) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // < 600px
    const isMoreThanSmall = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const isTall = useMediaQuery('(min-height:500px)'); // >= 500px height

    return (
        <>
            <Box>
                <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                        {isOverall ? "Overall" : "Class"} Position
                    </Typography>
                    <Typography variant={isSmall || (isMoreThanSmall && !isTall) ? "h3" : "h2"} component="div">
                        { curDriver ? classPosition : "" }
                    </Typography>
                    <Typography variant="standingsClassSmall" component="span" align="center" noWrap sx={{ backgroundColor: curDriver && classRacers && classRacers[curDriver.class] ? classRacers[curDriver.class].color : "inherit" }} >
                        { curDriver ? curDriver.class : "" }
                    </Typography>
                </Stack>
            </Box>
            <Box>
                <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                        {isOverall ? "Overall" : "Class"} Best
                    </Typography>
                    <Typography variant={isSmall || (isMoreThanSmall && !isTall) ? "h3" : "h2"} component="div">
                        { fastestClassRacer ? fastestClassRacer.bestLap : "" }
                    </Typography>
                    <Typography variant="h5" component="div">
                        { fastestClassRacer ? fastestClassRacer.name.toUpperCase() : "" }
                    </Typography>
                </Stack>
            </Box>  
        </>
    );
}

export default ClassPositionBest;
