import React from 'react'
import { Grid,Paper, Avatar, TextField, Button, Typography,Link, Checkbox, FormControlLabel } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from 'react';
import { API_IP } from './Vars';
import Alert from '@mui/material/Alert';

const Login = ( { handleLoginSubmit } ) => {

    const paperStyle={padding :20,height:'70vh',width:280, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loginError, setLoginError ] = useState('');
    const [ loginInfo, setLoginInfo ] = useState('');

    const handleSignInClick = (event) => {
        var myLoginInfo = `handleSignInClick user ${username} password ${password} api ${API_IP} \n`;
        setLoginInfo(myLoginInfo)

        event.preventDefault();

        let loginError = false;
        // console.log(`Sign in clicked, user: ${username}, password: ${password}`);
        fetch(`${API_IP}:3005/api/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": username.toLowerCase(),
                "password": password
            })
        })
        .then((response) => {
            console.log(`Sign in response HTTP code: ${response.status}, response: ${JSON.stringify(response)}`);
            myLoginInfo = myLoginInfo + `then 1 OK: ${response.ok} status ${response.status}` + "\n"
            setLoginInfo(myLoginInfo)
            loginError = !response.ok;
            // if (!response.ok) {
            //     throw new Error(`HTTP error! status: ${response.status}`);
            // }
            return response.json();
        })
        .then((jsonResponse) => {
            // console.log(JSON.stringify(jsonResponse));
            myLoginInfo = myLoginInfo + "then 2 \n" + JSON.stringify(jsonResponse) + "\n"
            setLoginInfo(myLoginInfo)

            if (loginError) {
                setLoginError(jsonResponse.message ? jsonResponse.message : "Unknown error");
                handleLoginSubmit(null);
            }
            else {
                setLoginError('');
                handleLoginSubmit(jsonResponse);
            }            
        })
        .catch((error) => {
            myLoginInfo = myLoginInfo + "error \n" + error + " \n"
            // if (error instanceof TypeError) {
            //     myLoginInfo = myLoginInfo + `message ${error.message}\n ` + `name ${error.name}\n ` + `stack ${error.stack}\n `
            // }
            setLoginInfo(myLoginInfo)
            console.log("Sign in error: " + error);
            handleLoginSubmit(null);
        });
    }

    return(
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <h2>Black Box</h2>
                     <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <h2>Sign In</h2>
                </Grid>
                { loginError ? <Alert sx={{ mb: 2 }} severity="error">Login error: {loginError}</Alert> : null }
                { loginInfo ? <Alert sx={{ mb: 2 }} severity="success">Login info: {loginInfo}</Alert> : null }
                <form onSubmit={ handleSignInClick }>
                    <TextField 
                        label='Username' 
                        placeholder='Enter username' 
                        variant="outlined" 
                        fullWidth 
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete='username'
                        sx={{ mb: 2 }}/>
                    <TextField 
                        label='Password' 
                        placeholder='Enter password' 
                        type='password' 
                        variant="outlined" 
                        fullWidth 
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete='current-password'
                        sx={{ mb: 2 }}/>
                    {/* <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Remember me"
                    /> */}
                    <Button 
                        type='submit' 
                        color='primary' 
                        variant="contained" 
                        style={btnstyle} 
                        fullWidth>
                            Sign in
                    </Button>
                </form>
                {/* <Typography >
                    <Link href="#" >
                        Forgot password ?
                    </Link>
                </Typography>
                <Typography > Do you have an account ?
                    <Link href="#" >
                        Sign Up 
                    </Link>
                </Typography> */}
            </Paper>
        </Grid>
    )
}

export default Login