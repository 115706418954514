import React from 'react';
import { matchDriver, isQualifying, getFastestRacer, getLastBestColumnColor, getIntervalBetweenRacers, getStandingsBestOrLastTime, getRelDiff } from './Utils';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ClassPositionBest from './ClassPositionBest';
import LastBestLap from './LastBestLap';
import LapsTime from './LapsTime';
import NearDriver from './NearDriver';
import BestVsLeaderBest from './BestVsLeaderBest';
import ClassViewTableRow from './ClassViewTableRow';
import ClassViewTableHeader from './ClassViewTableHeader';
import FirstTimeUserExperience from './FirstTimeUserExperience';
// import WbSunnyIcon from '@mui/icons-material/WbSunny';

const ClassView = ( {racers, classRacers, race, driver, headerHeight, raceState, isOverall} ) => {
    const [prevDriver, setPrevDriver] = React.useState(null);
    const [prevPrevDriver, setPrevPrevDriver] = React.useState(null);
    const [curDriver, setCurDriver] = React.useState(null);
    const [nextDriver, setNextDriver] = React.useState(null);
    const [nextNextDriver, setNextNextDriver] = React.useState(null);
    const [position, setPosition] = React.useState(null);
    const [fastestRacer, setFastestRacer] = React.useState(null);
    const [leaderRacer, setLeaderRacer] = React.useState(null);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // < 600px
    const isMoreThanSmall = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const isMedium = useMediaQuery(theme.breakpoints.down('md')); // < 1000px
    const isMoreThanMedium = useMediaQuery(theme.breakpoints.up('md')); // >= 1000px
    const isLarge = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const isTall = useMediaQuery('(min-height:500px)'); // >= 500px height

    const isPhonePortrait = isSmall && isTall;
    const isPhoneLandscape = isMoreThanSmall && !isTall;
    const isTabletPortrait = isMoreThanSmall && !isMoreThanMedium && isTall;
    const isTabletLandscape = isMoreThanMedium && isTall;



    useEffect(() => {
        if (!racers || !driver || racers.length == 0 || driver.length == 0) {
            return;
        }

        var myRacerIndex = -1;
        for (var i = 0; i<racers.length; i++) {
            if (matchDriver(driver, racers[i].name)) {
                myRacerIndex = i;
                break;
            }
        }

        if (myRacerIndex == -1) {
            setCurDriver(null);
            setPosition(null);
            setFastestRacer(null);
            setPrevDriver(null);
            setPrevPrevDriver(null);
            setNextDriver(null);
            setNextNextDriver(null);
            return;
        }

        setCurDriver(racers[myRacerIndex]);

        var myRacerClassIndex = -1;
        for (var j = 0; j<classRacers[racers[myRacerIndex].class].racers.length; j++) {
            if (matchDriver(driver, classRacers[racers[myRacerIndex].class].racers[j].name)) {
                myRacerClassIndex = j;
                break;
            }
        }

        if (myRacerClassIndex == -1) {
            console.log("ClassView: class racer not found");
            setPosition(null);
            setFastestRacer(null);
            setPrevDriver(null);
            setPrevPrevDriver(null);
            setNextDriver(null);
            setNextNextDriver(null);
            return;
        }  
        
        let effectiveRacerIndex = isOverall ? myRacerIndex : myRacerClassIndex;
        let effectiveRacers = isOverall ? racers : classRacers[racers[myRacerIndex].class].racers;

        setPosition(effectiveRacerIndex + 1);

        setFastestRacer(isOverall ? getFastestRacer(racers) : getFastestRacer(classRacers[racers[myRacerIndex].class].racers));
        setLeaderRacer(isOverall ? racers[0] : classRacers[racers[myRacerIndex].class].racers[0]);

        if (effectiveRacerIndex > 0) {
            setPrevDriver(effectiveRacers[effectiveRacerIndex - 1]);
        }
        else {
            setPrevDriver(null);
        }

        if (effectiveRacerIndex > 1) {
            setPrevPrevDriver(effectiveRacers[effectiveRacerIndex - 2]);
        }
        else {
            setPrevPrevDriver(null);
        }

        if (effectiveRacerIndex < effectiveRacers.length - 1) {
            setNextDriver(effectiveRacers[effectiveRacerIndex + 1]);
        }
        else {
            setNextDriver(null);
        }

        if (effectiveRacerIndex < effectiveRacers.length - 2) {
            setNextNextDriver(effectiveRacers[effectiveRacerIndex + 2]);
        }
        else {
            setNextNextDriver(null);
        }
    }, [racers, driver, classRacers]);

    return (
        <>
            { !driver ? <FirstTimeUserExperience message="To use this view, select a driver to follow using the top right menu."/> : null }
            { driver && !curDriver ? <FirstTimeUserExperience message={`No driver in the current race matches "${driver}". Update name or please wait for further race results.`}/> : null }
            { curDriver ? (<Stack spacing={(isMoreThanSmall && !isTall) ? 0 : 1} 
                direction="column" 
                justifyContent="flex-start" 
                alignItems="stretch" 
                divider={<Divider orientation="horizontal" flexItem />}
                sx={{ height: `calc(100% - ${headerHeight}px)`, paddingTop: (isMoreThanSmall && !isTall) ? 0 : 2 }}
                >
                <Box>
                    {/* Render a stack of components with row direction, space-between justification, stretch alignment, and spacing of 2.
                        The stack contains the LastBestLap component for the current driver.
                        If the screen size is more than small and tall, it also includes the LapsTime component.
                        If the screen size is more than small and not tall, it includes the ClassPositionBest component. */}
                    <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ flexGrow: 1 }}>
                        <LastBestLap curDriver={curDriver} />
                        { (isMoreThanSmall && isTall) && <LapsTime curDriver={curDriver} raceState={raceState} /> }
                        { (isMoreThanSmall && !isTall) && <ClassPositionBest curDriver={curDriver} fastestClassRacer={fastestRacer} classPosition={position} classRacers={classRacers} isOverall={isOverall} /> }
                    </Stack>
                </Box>
                <Box>
                    {/* Render a stack of components with row direction, space-around justification, stretch alignment, and spacing of 2.
                        The stack contains the ClassPositionBest component for small screens.
                        For screens that are more than small and tall, it includes the ClassPositionBest component, a vertical divider, and two boxes.
                        For screens that are more than small and not tall, it includes two NearDriver components separated by a vertical divider. */}
                    <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ flexGrow: 1 }}>
                        { (isSmall) && <ClassPositionBest curDriver={curDriver} fastestClassRacer={fastestRacer} classPosition={position} classRacers={classRacers} isOverall={isOverall} /> }
                        { (isMoreThanSmall && isTall) && (<>
                            <ClassPositionBest curDriver={curDriver} fastestClassRacer={fastestRacer} classPosition={position} classRacers={classRacers} isOverall={isOverall} />
                            <Divider orientation="vertical" flexItem /> 
                            <BestVsLeaderBest curDriver={curDriver} leaderRacer={leaderRacer} raceState={raceState} />
                        </>)}
                    { (isMoreThanSmall && !isTall) && (<>
                        <NearDriver curDriver={curDriver} otherDriver={nextDriver} classPosition={position} raceState={raceState} isAhead={false} />
                        <Divider orientation="vertical" flexItem />
                        <NearDriver curDriver={curDriver} otherDriver={prevDriver} classPosition={position} raceState={raceState} isAhead={true} />
                        </>)}
                    </Stack>
                </Box>
                { (isMoreThanSmall && isTall) && (<>
                    <Stack spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch">
                        <ClassViewTableHeader raceState={raceState} />
                        <ClassViewTableRow otherDriver={prevPrevDriver} curDriver={curDriver} raceState={raceState} classRacers={classRacers} position={position - 2} />
                        <ClassViewTableRow otherDriver={prevDriver} curDriver={curDriver} raceState={raceState} classRacers={classRacers} position={position - 1} />
                        <ClassViewTableRow otherDriver={curDriver} curDriver={curDriver} raceState={raceState} classRacers={classRacers} position={position} />
                        <ClassViewTableRow otherDriver={nextDriver} curDriver={curDriver} raceState={raceState} classRacers={classRacers} position={position + 1} />
                        <ClassViewTableRow otherDriver={nextNextDriver} curDriver={curDriver} raceState={raceState} classRacers={classRacers} position={position + 2} />
                    </Stack>
                </>)}
                { isSmall && (
                <Box>
                    <Stack spacing={1} direction="column" justifyContent="space-between" alignItems="stretch">
                        <NearDriver curDriver={curDriver} otherDriver={prevDriver} classPosition={position} raceState={raceState} isAhead={true} />
                        <Divider orientation="horizontal" flexItem />
                        <NearDriver curDriver={curDriver} otherDriver={nextDriver} classPosition={position} raceState={raceState} isAhead={false} />
                    </Stack>
                </Box>
                )}
            </Stack>) : null }
        </>
    );
};

export default ClassView;
