import { red, indigo, green, yellow, blue, lightGreen, brown, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

        // const classColors = [
        //     indigo[700],
        //     green[700],
        //     yellow.A700,
        //     red[700],
        //     blue[500],
        //     lightGreen[400],
        //     brown[600],
        //     gray[700]
        // ];

// A custom theme for this app
const createMyTheme = (isDarkMode) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      classOne: indigo[800],
      classTwo: green[800],
      classThree: yellow[800],
      classFour: red[800],
      classFive: blue[800],
      classSix: lightGreen[800],
      classSeven: brown[800],
      classEight: grey[800],

      // standingsNumber: {
      //   main: red[900],
      // },
    //   mode: 'dark',
    //   primary: {
    //     main: '#5893df',
    //   },
    //   secondary: {
    //     main: '#2ec5d3',
    //   },
    //   background: {
    //     default: '#192231',
    //     paper: '#24344d',
    //   },
      mode: isDarkMode ? 'dark' : 'light',
    },
    // components: {
    //   MuiTypography: {
    //     variants: [
    //       {
    //         props: { variant: 'standingsNumber' },
    //         style: {
    //           backgroundColor: "secondary.dark", 
    //         }
    //       },
    //       {
    //         props: { variant: 'standingsPosition' },
    //         style: {
    //           backgroundColor: "primary.light",
    //           // color: 'white',
    //         }
    //       }
    //     ],
    //   },
    // },
    typography: {
      fontFamily: 'Roboto Condensed',
      h1 : {
        fontSize: '7rem',
      },
      h2 : {
        fontSize: '4rem',
      },
      h3 : {
        fontWeight: 300
      },
      headerText: {
        fontSize: '1.25rem',
        color: isDarkMode ? "white" : "black", 
      },
      standingsName: {
        fontSize: '2rem',
      },
      standingsTime: {
        fontSize: '2rem',
        fontWeight: 300,
      },
      standingsClass: {
        fontSize: '2rem',
        color: "white", 
      },
      standingsClassSmall: {
        fontSize: '1rem',
        color: "white", 
      },
      standingsNumber: {
        fontFamily: 'Chivo Mono',
        fontWeight: 600,
        fontSize: '2rem',
        backgroundColor: 'lightblue', 
        color: "black", 
        borderRadius: 3
      },
      standingsPosition: {
        fontFamily: 'Chivo Mono',
        fontWeight: 600,
        fontSize: '2rem',
        color: "white", 
      }
    },
  });
    //   secondary: {
    //     main: '#19857b',
    //   },
    //   error: {
    //     main: red.A400,
    //   },
    // },
  return theme;
}

export default createMyTheme;
