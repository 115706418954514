// Utility Library

const matchDriver = (submittedDriver, dataDriver) => {
    if (submittedDriver == null || submittedDriver.length < 1 || dataDriver == null) {
        return false;
    }

    return dataDriver.toLowerCase().includes(submittedDriver.toLowerCase());
}

const getAuthHeader = (authUser) => {
    // console.log("RaceStateContainer: getAuthHeader: " + JSON.stringify(authUser));
    return authUser ? { 'x-access-token' : authUser.accessToken } : {};
};

const isQualifying = (raceState) => {
    return raceState && raceState.sessionType && raceState.sessionType[0].toLowerCase() != "r";
}

const getIntervalBetweenRacers = (curRacer, compRacer) => {
    // console.log("getIntervalBetweenRacers: curRacer: " + curRacer.diff + " compRacer: " + compRacer.diff);
    if (!curRacer || !compRacer) {
        return null;
    }

    // Handle things like "1 LAP" interval
    // It's a difference in laps down
    if (curRacer.diff.includes("LAP") && compRacer.diff.includes("LAP")) {
        // extract number of laps from a string looking like "1 LAP"
        const curLap = parseInt(curRacer.diff.split(" ")[0]);
        const compLap = parseInt(compRacer.diff.split(" ")[0]);
        const diffLap = compLap - curLap; 
        const absDiffLap = Math.abs(diffLap); // Calculate the absolute value of the difference
        if (diffLap == 0) {
            return ""; // TODO should use the gap field in cases like this
            // algo: sum up the gap fields of all the racers between the two, not including the 
            // gap field from the racer that's ahead
        }
        const intervalString = `${diffLap > 0 ? "+" : ""}${diffLap} LAP${absDiffLap > 1 ? "S" : ""}`;
        return intervalString;
    }

    // Only the comp racer is laps down, special logic to account for racers pending getting on leaders lap
    // Because of that, disregard the delta if the laps down racer is the one that's ahead
    if (compRacer.diff.includes("LAP")) {
        if (compRacer.position < curRacer.position) {
            return "";
        }
        else {
            return compRacer.diff;
        }
    }

    if (curRacer.diff.includes("LAP")) {
        if (curRacer.position < compRacer.position) {
            return "";
        }
        else {
            return curRacer.diff;
        }
    }

    // If one of the diffs is blank, it means that racer is the leader
    // Assign the diff to the other racer with an appropriate sign
    // FIX blank diff fields can also be caused by a racer pitting in
    if (curRacer.diff == "") {
        return `+${compRacer.diff}`;
    }
    else if (compRacer.diff == "") {
        return `-${curRacer.diff}`;
    }

    // Now we are dealing with a purely lap time based delta, pfew!
    return getTimeDiff(curRacer.diff, compRacer.diff);
}

const getRaceIntervalBetweenRacers = (curRacer, compRacer) => {
    if (!curRacer || !compRacer) {
        return null;
    }

    return getTimeDiff(curRacer, compRacer);
}

const getStandingsBestOrLastTime = (raceState, curRacer, compRacer) => {
    if (!raceState || !curRacer || !compRacer) {
        return null;
    }

    if (isQualifying(raceState)) {
        return compRacer.bestLap;
    }

    // Calculate the current racer's delta vs comp racer in terms of last lap
    if (!curRacer.lastLap || !compRacer.lastLap) {
        return null;
    }

    return getTimeDiff(compRacer.lastLap, curRacer.lastLap);
}

const isCurRacerLastFaster = (raceState, curRacer, compRacer) => {
    const deltaLast = getStandingsBestOrLastTime(raceState, curRacer, compRacer);
    return deltaLast && deltaLast[0] == "-";
}

const getLastBestColumnColor = (raceState, curRacer, compRacer) => {
    if (!raceState || !curRacer || !compRacer || curRacer == compRacer) {
        return 'inherit';
    }

    if (isQualifying(raceState)) {
        return 'inherit';
    }

    return isCurRacerLastFaster(raceState, curRacer, compRacer) ? 'green' : 'red';
}

const laptimeToMs = (laptime) => {
    // decode the time to ms from a string like "1:23.456"
    if (!laptime) {
        return null;
    }

    let [min1, sec1, ms1] = laptime.split(/[:.]/);
    if (ms1 == undefined) {
        ms1 = sec1;
        sec1 = min1;
        min1 = 0;
    }
    const curTime = parseInt(min1) * 60000 + parseInt(sec1) * 1000 + parseInt(ms1);
    return curTime;
}

const getTimeDiff = (curRacerTime, compRacerTime) => {
    const curTime = laptimeToMs(curRacerTime);
    const compTime = laptimeToMs(compRacerTime);
    const deltaTime = compTime - curTime;
    const absDeltaTime = Math.abs(compTime - curTime);

    // Convert the deltaTime interval to a string like "1:23.456"
    const deltaMin = Math.floor(absDeltaTime / 60000);
    const deltaSec = Math.floor((absDeltaTime - deltaMin * 60000) / 1000);
    const deltaMs = absDeltaTime - deltaMin * 60000 - deltaSec * 1000;
    const intervalString = `${deltaMin > 0 ? deltaMin + ":" : ""}${deltaSec}.${deltaMs}`;
    return deltaTime < 0 ? "-" + intervalString : "+" + intervalString;
}

const getFastestRacer = (racers) => {
    if (!racers || racers.length == 0) {
        return null;
    }

    let fastestRacer = racers[0];
    if (!fastestRacer.bestLap) {
        return null;
    }

    racers.forEach((racer) => {
        const time1 = laptimeToMs(fastestRacer.bestLap);
        if (!racer.bestLap) {
            return;
        }
        const time2 = laptimeToMs(racer.bestLap);
        
        if (time2 < time1) {
            fastestRacer = racer;
        }
    });

    return fastestRacer;
}

const getRelDiff = (curDriverLap, refDriverLap) => {
    if (!curDriverLap || !refDriverLap) {
        return null;
    }

    let curLapMs = laptimeToMs(curDriverLap);
    let refLapMs = laptimeToMs(refDriverLap);

    // console.log("curDriverLap:", curDriverLap);
    // console.log("refDriverLap:", refDriverLap);

    let diff = curLapMs - refLapMs;
    let relDiff = (diff / refLapMs * 100).toFixed(2) + "%";
    relDiff = diff > 0 ? "+" + relDiff : relDiff;

    return relDiff;
}

// Exporting the utility functions
module.exports = {
    matchDriver,
    getAuthHeader,
    isQualifying,
    getIntervalBetweenRacers,
    getRaceIntervalBetweenRacers,
    getStandingsBestOrLastTime,
    isCurRacerLastFaster,
    getLastBestColumnColor,
    getTimeDiff,
    getFastestRacer,
    getRelDiff
};
