import React from 'react';
import { Typography, Paper } from '@mui/material';

function FirstTimeUserExperience( { message }) {
    return (
        <div>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h3" component="div" align="center">
                    { message }
                </Typography>
            </Paper>
        </div>
    );
}

export default FirstTimeUserExperience;
