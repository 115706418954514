import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { getStandingsBestOrLastTime, isQualifying, getLastBestColumnColor, getIntervalBetweenRacers } from './Utils';

function NearDriver({ curDriver, otherDriver, classPosition, raceState, isAhead }) {
  return (
    <Stack spacing={1} direction="column" justifyContent="space-between" alignItems="stretch">
        <Typography variant="h6" component="span" >{isAhead ? "Ahead" : "Behind"}</Typography>
        { otherDriver ? (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch" divider={<Divider orientation="vertical" flexItem />} spacing={1} sx={{ flexGrow: 1 }}>
                <Typography variant="standingsPosition" component="span" align="center" sx={{ minWidth: "1rem", fontSize: "1.5rem", backgroundColor: 'secondary.dark' }} >
                    {otherDriver ? (isAhead ? classPosition - 1 : classPosition + 1) : ""}
                </Typography>
                <Typography noWrap variant="standingsName" sx={{ flexGrow: 1, fontSize: "1.5rem" }}>
                    {otherDriver ? otherDriver.name.toUpperCase() : ""}
                </Typography>
                <Typography variant="standingsNumber" component="span" align="center" sx={{ minWidth: "1rem", fontSize: "1.5rem", backgroundColor: 'secondary.dark' }} >
                    {otherDriver ? otherDriver.number : "" }
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="stretch" divider={<Divider orientation="vertical" flexItem />} sx={{ flexGrow: 1 }}>
                <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ minWidth: 1/12, fontSize: '1rem' }} align="left">
                        Interval
                    </Typography>
                    <Typography variant="standingsTime" sx={{ minWidth: 1/10 }} align="left">
                        {otherDriver ? getIntervalBetweenRacers(curDriver, otherDriver) : ""}
                    </Typography>
                </Stack>
                <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ minWidth: 1/12, fontSize: '1rem' }} align="right">
                        { isQualifying(raceState) ? "Best" : "Vs Last" }
                    </Typography>
                    <Typography variant="standingsTime" sx={{ minWidth: 1/10, color: getLastBestColumnColor(raceState, curDriver, otherDriver) }} align="right">
                        {otherDriver ? getStandingsBestOrLastTime(raceState, curDriver, otherDriver) : ""}
                    </Typography>
                </Stack>
            </Stack>
        </>
        ) : (
            <Box sx={{ flexGrow: 1, height: "3rem" }}></Box>
        )}
    </Stack>
  );
}

export default NearDriver;
