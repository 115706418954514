import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Races from './Races';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import RaceStateContainer from './RaceStateContainer';
import { Divider, Drawer, Switch } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SelectRaceDialog from './SelectRaceDialog';
import TimeToLeaveSharpIcon from '@mui/icons-material/TimeToLeaveSharp';
import PersonSearchSharpIcon from '@mui/icons-material/PersonSearchSharp';
import TimerIcon from '@mui/icons-material/Timer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField, Button } from '@mui/material';
import Login from './Login';
import UserContext from './UserContext';
import LogoutIcon from '@mui/icons-material/Logout';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import createMyTheme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { FormControlLabel, FormGroup } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Black Box
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [dialogMenu, setDialogMenu] = useState(false);
  const [selectedRace, setSelectedRace] = useState(() => {
    // Get the initial value from LocalStorage
    const savedRace = localStorage.getItem('selectedRace');
    return savedRace ? JSON.parse(savedRace) : null;
  });
  const [selectDriverOpen, setSelectDriverOpen] = useState(false);
  const [driverName, setDriverName] = useState(() => {
    // Get the initial value from LocalStorage
    const savedDriver = localStorage.getItem('selectedDriver');
    return savedDriver ? JSON.parse(savedDriver) : null;
  });
  const [tempDriverName, setTempDriverName] = useState("");
  const [selectedView, setselectedView] = useState(() => {
    // Get the initial value from LocalStorage
    const localStorageSelectedView = localStorage.getItem('selectedView');
    return localStorageSelectedView ? JSON.parse(localStorageSelectedView) : 'standings';
  });
  // const [userName, setUserName] = useState("");
  const [user, setUser] = useState(() => {
    // Get the initial value from LocalStorage
    const thisUser = localStorage.getItem('user');
    const thisUserObject = thisUser ? JSON.parse(thisUser) : null;
    // return { ...thisUserObject, logout: () => setUser(null) };
    return thisUserObject;
  });

  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Get the initial value from LocalStorage
    const isDarkMode = localStorage.getItem('isDarkMode');
    return isDarkMode ? JSON.parse(isDarkMode) : true;
  });
  
  useEffect(() => {
    // Store the isDarkMode in LocalStorage whenever it changes
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);  

  useEffect(() => {
    // Store the selectedRace in LocalStorage whenever it changes
    localStorage.setItem('selectedRace', JSON.stringify(selectedRace));
  }, [selectedRace]);

  useEffect(() => {
    // Store the selectedDriver in LocalStorage whenever it changes
    localStorage.setItem('selectedDriver', JSON.stringify(driverName));
  }, [driverName]);

  useEffect(() => {
    // Store the selectedView in LocalStorage whenever it changes
    localStorage.setItem('selectedView', JSON.stringify(selectedView));
  }, [selectedView]);

  useEffect(() => {
    // Store the user in LocalStorage whenever it changes
    localStorage.setItem('user', user ? JSON.stringify(user) : null);
  }, [user]);

  function handleDialogOpen() {
    // console.log('dialog opened');
    setDialogMenu(true);
  }

  function handleDialogClose() {
    // console.log('dialog closed');
    setDialogMenu(false);
  }

  function handleClick() {
    // console.log('selectRace clicked');
    setSettingsMenu(!settingsMenu);
  }

  function handleSelectDriver() {
    // console.log('handleSelectDriver clicked');
    setTempDriverName(driverName);
    setSelectDriverOpen(!selectDriverOpen);
  }

  function handleSelectDriverClose() {
    // console.log('handleSelectDriverClose');
    setSelectDriverOpen(false);
  }

  function setSelectedRaceHandler(selectedRace) {
    // console.log(`App: setting selected race to "${selectedRace.name}"`);
    setSelectedRace(selectedRace);
  }

  const handleSelectDriverSubmit = (event) => {
    event.preventDefault();
    setDriverName(tempDriverName);
    // console.log(driverName);
    handleSelectDriverClose();
  };

  const handleSelectStandingsView = () => {
    // console.log('handleSelectStandingsView clicked');
    setselectedView("standings");
  };

  const handleSelectTimingView = () => {
    // console.log('handleSelectTimingView clicked');
    setselectedView("timing");
  };

  const handleSelectClassView = () => {
    // console.log('handleSelectTimingView clicked');
    setselectedView("class");
  };

  const handleSelectOverallView = () => {
    // console.log('handleSelectTimingView clicked');
    setselectedView("overall");
  };

  const handleLoginSubmit = (user) => {
    // console.log(`App: handleLoginSubmit: ${user && user.username ? user.username : "null"}`);
    // setUserName(user && user.username ? user.username : "");
    // const userObject = { ...user, logout: () => setUser(null) };
    setUser(user);
  }

  const logout = () => {
    setUser(null);
  };

  return (
    <ThemeProvider theme={createMyTheme(isDarkMode)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <UserContext.Provider value={{ authUser: user, logout: logout }}>
        {/* <TopBar settingsClickHandler={ handleClick }/> */}

        { user && (
          <>
            <Drawer anchor="right" open={settingsMenu} >
              <Box
                sx={{ width: '300px', height: '100vh' }}
                role="presentation"  
                onClick={handleClick}       
              >
                <List>
                  <ListItem key="Standings" disablePadding>
                    <ListItemButton onClick={() => handleSelectStandingsView()}>
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Standings View" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Class" disablePadding>
                    <ListItemButton onClick={() => handleSelectClassView()}>
                      <ListItemIcon>
                        <NoCrashIcon />
                      </ListItemIcon>
                      <ListItemText primary="Class View" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Overall" disablePadding>
                    <ListItemButton onClick={() => handleSelectOverallView()}>
                      <ListItemIcon>
                        <MinorCrashIcon />
                      </ListItemIcon>
                      <ListItemText primary="Overall View" />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem key="Timing" disablePadding>
                    <ListItemButton onClick={() => handleSelectTimingView()}>
                      <ListItemIcon>
                        <TimerIcon />
                      </ListItemIcon>
                      <ListItemText primary="Timing View" />
                    </ListItemButton>
                  </ListItem> */}
                  <Divider />
                  <ListItem key="Race" disablePadding>
                    <ListItemButton onClick={() => handleDialogOpen()}>
                      <ListItemIcon>
                        <TimeToLeaveSharpIcon />
                      </ListItemIcon>
                      <ListItemText primary="Select Race" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Driver" disablePadding>
                    <ListItemButton onClick={() => handleSelectDriver()}>
                      <ListItemIcon>
                        <PersonSearchSharpIcon />
                      </ListItemIcon>
                      <ListItemText primary="Select Driver" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Dark mode" disablePadding>
                    <ListItemButton onClick={() => setIsDarkMode(!isDarkMode)}>
                      <ListItemIcon>
                        <FormGroup>
                          <FormControlLabel control={<Switch checked={isDarkMode} onChange={() => setIsDarkMode(!isDarkMode)} />} />
                        </FormGroup>
                      </ListItemIcon>
                      <ListItemText primary="Dark Mode" />
                    </ListItemButton>
                  </ListItem>
                  {/* <Switch checked={isDarkMode} label="Dark mode" onChange={() => setIsDarkMode(!isDarkMode)} /> */}
                  <Divider />
                  <ListItem key="Logout" disablePadding>
                    <ListItemButton onClick={() => logout()}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Log Out" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Username" disablePadding>
                    <ListItemButton>
                      <ListItemText primary={`Username: ${user.username}`} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Drawer>

            {dialogMenu ? <SelectRaceDialog dialogOpen={dialogMenu} handleClose={ handleDialogClose } handleDialogSelectedRace={ setSelectedRaceHandler } curSelectedRace={selectedRace}/> : null}

            <Dialog open={selectDriverOpen} onClose={handleSelectDriverClose}>
              <DialogTitle>Select Driver</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Enter the full or partial name of a driver to track as part of Black Box.
                </DialogContentText>
                  <form onSubmit={handleSelectDriverSubmit}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Driver Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={tempDriverName}
                      onChange={(e) => setTempDriverName(e.target.value)}
                    />
                  <DialogActions>
                    <Button onClick={handleSelectDriverClose}>Cancel</Button>
                    <Button type="submit">Set</Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog>

            <RaceStateContainer selectedRace={selectedRace} selectedDriver={driverName} changeSelectedDriverHandler={ setDriverName } settingsClickHandler={ handleClick } selectedView={ selectedView } changeSelectedViewHandler={ setselectedView}/>
          </>
          )}

          { !user && (
            <Login handleLoginSubmit={ handleLoginSubmit }/>
          )}
      </UserContext.Provider>
    </ThemeProvider>
  );
};
