import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

const LapsTime = ({curDriver, raceState}) => {
    return (
        <>
        <Box>
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" align='right'>
                    Laps
                </Typography>
                <Typography variant="h2" component="div" align='right'>
                    { curDriver ? curDriver.laps : ""}
                </Typography>
            </Stack>
        </Box>
        <Box>
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" align='right'>
                    Time
                </Typography>
                <Typography variant="h2" component="div" align='right'>
                    { raceState && raceState.time ? raceState.time : ""}
                </Typography>
            </Stack>
        </Box>
    </>
);
}

export default LapsTime;
