import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import { getIntervalBetweenRacers, getStandingsBestOrLastTime, getLastBestColumnColor, isQualifying } from './Utils';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const ClassViewTableRow = ({ otherDriver, curDriver, raceState, classRacers, position }) => {
    if (!otherDriver || !curDriver || !raceState || !classRacers || !position) {
        return null;
    }

    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md')); // < 1000px

    return (
        <Stack direction="row" 
        spacing={2} 
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="space-between"
        sx={{ flexGrow: 1 }}>
            <Typography variant="standingsPosition" component="span" align="center" sx={{ width: '5%', backgroundColor: 'secondary.dark', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                { position }
            </Typography>
            <Typography variant="standingsClass" component="span" align="center" noWrap sx={{ width: '8%', backgroundColor: otherDriver && classRacers && classRacers[otherDriver.class] ? classRacers[otherDriver.class].color : "inherit", ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                { otherDriver.class }
            </Typography>
            <Typography noWrap variant="standingsName" sx={{ width: '40%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }}>
                { otherDriver.name.toUpperCase() }
            </Typography>
            <Typography variant="standingsNumber" component="span" align="center" sx={{ width: '8%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                { otherDriver.number  }
            </Typography>
            <Typography variant="standingsTime" sx={{ width: '13%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} align="right">
                { (curDriver != otherDriver) ? getIntervalBetweenRacers(curDriver, otherDriver) : "" }
            </Typography>
            <Typography variant="standingsTime" sx={{ width: '12%', color: getLastBestColumnColor(raceState, curDriver, otherDriver), ...(isMedium ? { fontSize: '0.95rem' } : {}) }} align="right">
                { (curDriver != otherDriver) ? getStandingsBestOrLastTime(raceState, curDriver, otherDriver) : (isQualifying(raceState) ? curDriver.bestLap : curDriver.lastLap) }
            </Typography>
        </Stack>
    );
};

export default ClassViewTableRow;
